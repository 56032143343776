var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "contentsColClass": "",
      "contentsClass": "mw-1130px"
    }
  }, [_c('page-section', {
    staticClass: "page-section--first pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit"
  }, [_vm._v("새로운 소식")])]), _c('router-view', {
    attrs: {
      "name": "board",
      "code": "community",
      "category": "news",
      "skin": "about-news",
      "limit": 8
    }
  })], 1), _c('page-section', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("회사소개")];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "background-position",
    attrs: {
      "img": "/images/sub/about/about-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 700 / 400,
      "content-class": "d-flex align-center"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135 white--text"
  }, [_vm._v(" 공간에 가치를 더합니다."), _c('br'), _vm._v(" 언제나 새로운 제품을 선보입니다."), _c('br'), _vm._v(" 더 편하고 쉬운 서비스를 고민합니다."), _c('br'), _vm._v(" 항상 고객의 입장을 우선시 합니다."), _c('br'), _vm._v(" 국내 최초 전담팀 서비스를 운영합니다. ")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('about-community-article')], 1)], 1)], 1), _c('page-section', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("Since 2001.")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("비전, 담대한 시작.")])]), _c('v-card', [_c('v-img', {
    attrs: {
      "aspect-ratio": 1330 / 400,
      "src": "/images/sub/about/about-img2.jpg"
    }
  })], 1), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("조이포라이프는 2001년부터 삶의 질을 보다 풍부하게 만들어 줄 욕실을 만들기 위해많은 연구와 개발을 해왔습니다. 조이포라이프는 스테인리스 스틸을 기반으로 한 높은 품질의 매립 샤워기와 디지털 샤워 시스템으로 전 세계 욕실 시장에서 가장 진보적인 제품을 만드는 회사로 손꼽혀 왔습니다. 조이포라이프의 디자인하우스와 생산 시스템은 사용자를 깊이 이해하고 그에 적합한 제품을 개발하기 위한 노력을 기울이는 동시에 최고급의 품질을 유지하기 위한 시스템을 구축하고 있습니다. 창사 이래 우리의 가장 큰 목표는 기능과 디자인 모두를 충족하는 솔루션을 제공하는 회사로 발돋움 하는 것이었고 이제 그 영역을 넓혀 거주 공간에 대한 관심, 특히 욕실과 주방에 대한 새로운 문화를 만들고 그 공간에 대한 새로운 해석을 고객분들께 제공하고자 합니다. 이에 조이포라이프는 욕실 및 주방과 관련된 모든 것을 제공하는 최고의 커머스 플랫폼으로 진화를 꿈꾸고 있습니다. 23년의 탄탄한 제조 경험과 대규모 프로젝트 납품을 통해 고객으로부터 받아 온 신뢰, 그리고 우리의 열정을 토대로 대한민국 욕실/주방의 새로운 미래를 열어 가겠습니다.")])])], 1), _c('page-section', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("우리는 누구인가")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("비지니스, 디자인 및 혁신의 대담한 여정.")])]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("조이포라이프는 2001년 설립 이래 23년 동안 샤워 전문 제조 경험과 전문성을 바탕으로 수입 욕실/주방 최고의 솔루션을 제공하는 욕실/주방 전문 기업입니다. 조이포라이프는 자사 제품 개발 및 제조, 해외수출을 통해 그 기술력을 입증해 왔으며 이를 토대로 국내 다양한 대형 재건축, 재개발 프로젝트 납품을 수행해 왔습니다. 또한 국내 최초 콜러 공식 시그니처 쇼룸 운영과 국내 최다 수입브랜드 취급 전문몰을 자체 운영함으로써 국내 프리미엄 수입 욕실/주방 분야에서 여러분의 최고 파트너가 되겠습니다.")]), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 1130 / 124,
      "src": "/images/logo-outlined.png"
    }
  })], 1), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100 mx-auto",
    attrs: {
      "img": "/images/sub/about/about-img3.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 316 / 600 : 1 / 1,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("HEAD OFFICE")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100 mx-auto",
    attrs: {
      "img": "/images/sub/about/about-img4.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 316 / 600 : 1 / 1,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("FACTORY")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100 mx-auto",
    attrs: {
      "img": "/images/sub/about/about-img5.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 316 / 600 : 1 / 1,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("SHOWROOM")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100 mx-auto",
    attrs: {
      "img": "/images/sub/about/about-img6.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 316 / 600 : 1 / 1,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("PRODUCTS")])])])], 1)], 1)], 1)], 1)]), _c('page-section', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("우리의 브랜드&사업")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("더 나은 내일에 대한 설계")])]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("당신의 삶을 보다 풍요롭게. 당신의 디자인 요구를 충족시키는 욕실 및 주방 브랜드를 소개하고 아직 경험하지 못한 우아한 솔루션을 제공하여 새로운 욕실 및 주방 문화를 창조하기 위한 우리의 노력이 곧 우리의 사업영역이고 기회입니다. 더 나아가 우리는 우리의 거주 공간에 대한 아이디어를 바탕으로 새로운 솔루션에 대해 고민하고 있습니다. 의미가 만들어지고, 문제점을 개선하고, 연결을 활성화 시키며 기회가 존재하는 공간에서 우리 삶의 방식에 대한 실용성과 열망에 대해 진지하게 접근하고 있습니다. 라이프스타일 브랜드를 추구하는 기업으로서 우리가 보는 방식, 디자인, 그리고 항상 최선을 다할 수 있다는 근본적인 믿음이 성공의 열쇠를 쥐고 있습니다.")]), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("1")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("China/USA")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("2")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo2.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Germany")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("3")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo3.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Italy/Turkey")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("4")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo4.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("India")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("5")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo5.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("China")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("6")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo6.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Italy")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("7")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo7.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("China")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("8")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo8.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Sweden")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("9")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo9.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("France")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("10")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo10.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Italy")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("11")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo11.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Germany")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("12")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo12.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Italy")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("13")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo13.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Germany")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("14")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo14.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Germany")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("15")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo15.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Germany")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("16")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo16.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("China")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("17")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo17.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Germany")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("18")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo18.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Germany")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("19")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo19.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("China")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("20")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo23.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("China")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex flex-column justify-space-between"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-bold"
  }, [_vm._v("21")])]), _c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "aspect-ratio": 210 / 88,
      "src": "/images/sub/about/about-logo25.png"
    }
  })], 1), _c('div', {
    staticClass: "pa-16px pa-md-20px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Germany")])])])], 1)], 1)], 1)], 1)]), _c('page-section', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("유산")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("2001창립, 과거와 현재 그리고 미래")])]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("끊임없는 도전정신. 2001년 이후 23년 동안 국내 주거환경 문화를 바꾸기 위해 꿋꿋하게 걸어왔습니다. 그동안 걸어온 이 길이 바로 우리의 출발점이자 앞으로 나가야 할 목표입니다. 고객 지향형 서비스를 지향하며 새로운 주거 공간 문화를 창조하는 조이포라이프의 성장은 바로 고객 지향형 브랜드에 기반합니다.")]), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2001")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("주식회사 조이포라이프 설립")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2007")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("ISO 9001 인증")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("ISO 14001 인증")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2008")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("GIO 샤워시스템 Good Design 획득")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("GIO 샤워시스템 개발")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("LOSH 샤워 50종")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("기업부설연구소 설립")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2009")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("기업부설연구소 디자인 개발실 인증")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("cUPC인증(미국)")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("TOTO 수출계약")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("미국 애틀란타 KBIS전시회 출품")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2010")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Sililor Laufen에 rain shower head 공급사로 지정")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("세계 최초 Full touch shower System T-shower 출시")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("미국 시카고 KBIS전시회 참가")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2011")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("상하이 KBC 최초 참가")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("독일 ISH 최초 참가")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("창립 10주년 기념")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("유망 중소기업 인증")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("업체별 원산지인증 수출자 인증")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("IF 디자인어워드 T-Shower and GIO® 수상")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2012")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("벤쳐기업인증")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("도록동 쇼룸 오픈")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("KOHLER 한국 대리점 계약")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2013")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("제주 헬스케어타운 욕실부문 턴키납품(1차)")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("3백만불 수출탑 수상")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("KC 인증 획득")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2014")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("제주 헬스케어타운 욕실부문 턴키납품(2차)")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("제2공장 설립 (자동화라인 1호기 설치)")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2015")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("5T 샤워 디자인, 개발 완료")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("화성 사옥, 공장 이전")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("중국 KBIS전시회 개최")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("인도 JAQUAR 11억 유상증자")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2016")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("뿌리기업 인증 획득")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("KIMES(의료기) 전시회 개최")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("글로벌 강소기업 인증 취득")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("5백만불 수출탑 수상")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("제2공장 자동도금화라인 2호기 증설")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2017")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Kohler Kitchen 런칭")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("6.5억 유상증자")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("글로벌 강소기업 인증")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("지사설립(프랑크푸르트)")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2018")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("콜러 주방 가구 런칭")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("유망중소기업 인증취득")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("부산 LCT LED-SHOWER 납품")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("미아 9-1 재건축 아파트 주방가구 및 욕실부분 턴키 납품")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("LUMOS LED 샤워헤드 신제품 개발")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2019")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("독일 ISH 최초 참가")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("120억 미아 9-1 재건축아파트 주방가구 및 욕실부분 턴키 납품")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("90억 제주드림타워 재건축 아파트 욕실부분 턴키 납품")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2020")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("개포4단지 콜러욕실 110억 수주")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2021")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Aran world의 (Denovo cucine)런칭")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("부산 협성마리나 콜러주방 918세대")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2022")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("제주 포레팰리스 타운하우스 계약")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("광주 생타드레스 Denovo, Newform 설치")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("주안 파크자이, 흑석리버 파크자이, 개포프레지던트 자이 욕실용품 납품")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey darken-4",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02"
  }, [_vm._v("2023")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("미성크로바 1861세대 계약")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("북서울 자이 미아 3555세대 납품")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("온라인공식몰 오픈")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("엔젤 리프트 체어 런칭")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("천만불 수출탑 수상")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2024")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  })])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02"
  }, [_vm._v("2025")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  })])], 1)], 1)], 1)], 1)]), _c('page-section', {
    staticClass: "page-section--last",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("우리의 제품")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("도전, 그리고 열정.")])]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("더 나은 삶의 실현. 조이포라이프는 고객 감동을 최우선으로 좋은 품질의 제품, 효율적인 공정, 그리고 최고의 원료로 제품을 생산합니다. 다년간의 제조 경험과 수출 경험을 토대로 엄격하고 신뢰할 수 있는 품질의 제품을 생산해 왔고 엄격한 품질 관리는 이미 해외에서 검증받았습니다.")]), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "img": "/images/sub/about/about-img7.jpg",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02"
  }, [_vm._v("1")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("샤워헤드")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("LUMOS")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("LUXURY")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("PREMIUM")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("LOSH")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "img": "/images/sub/about/about-img8.jpg",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02"
  }, [_vm._v("2")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("TNWJS")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("D-TOUCH")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "img": "/images/sub/about/about-img9.jpg",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02"
  }, [_vm._v("3")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("핸드샤워")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("슬라이드바")])])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "img": "/images/sub/about/about-img10.jpg",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02"
  }, [_vm._v("4")])]), _c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("액세서리")])])], 1)], 1)])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-divider', {
    staticClass: "grey darken-4 mb-20px mb-md-30px",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "1"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 430 / 590,
      "src": "/images/sub/about/about-cover.jpg"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--md"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px"
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("다운로드")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "1"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 430 / 590,
      "src": "/images/sub/about/about-cover2.jpg"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--md"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px"
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("다운로드")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "pt-20px pt-md-0"
  }, [_c('v-card', {
    attrs: {
      "elevation": "1"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 430 / 590,
      "src": "/images/sub/about/about-cover3.jpg"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--md"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px"
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("다운로드")])], 1)], 1)], 1)], 1)])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }