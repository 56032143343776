<template>
    <client-page contentsColClass="">
        <page-section class="page-section--first page-section--last">
            <template #tit>조이포라이프 공식몰 그랜드 오픈 이벤트</template>
            <v-img src="/images/sub/event/open-event.jpg" />
        </page-section>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        PageSection,
    },
    data() {
        return {
            btn_primary,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
};
</script>
