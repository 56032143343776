var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "contentsColClass": ""
    }
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("조이포라이프 공식몰 그랜드 오픈 이벤트")];
      },
      proxy: true
    }])
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/event/open-event.jpg"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }