<template>
    <v-fade-transition>
        <div class="d-flex flex-column h-100 py-xl-20px" v-show="board">
            <div>
                <p class="txt txt--sm txt--dark mb-8px">{{ categoryText }} ({{ board?.createdAt?.toDate?.()?.replaceAll?.("-", ".")?.slice?.(0, 7) }})</p>
                <h3 class="tit tit--xs line-height-135">{{ board?.subject }}</h3>
            </div>
            <div class="btn-wrap mt-auto">
                <v-btn v-bind="{ ...btn_primary, ...$attrs }" x-large class="min-w-200px" :to="`/community/${board?.category}/${board?._id}`">보러가기</v-btn>
            </div>
        </div>
    </v-fade-transition>
</template>

<script>
import { BOARD_CATEGORIES__COMMUNITY, btn_primary } from "@/assets/variables";
import api from "@/api";

export default {
    data: () => ({
        btn_primary,

        board: null,
    }),
    computed: {
        categoryText() {
            return BOARD_CATEGORIES__COMMUNITY[this.board?.category]?.text || "게시글";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { boards } = await api.v1.boards.gets({
                headers: { limit: 1 },
                params: { code: "community" },
            });
            this.board = boards?.[0];
        },
    },
};
</script>

<style></style>
