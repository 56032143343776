var render = function render(){
  var _vm$board, _vm$board$createdAt, _vm$board$createdAt$t, _vm$board$createdAt$t2, _vm$board$createdAt$t3, _vm$board$createdAt$t4, _vm$board$createdAt$t5, _vm$board2, _vm$board3, _vm$board4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-fade-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.board,
      expression: "board"
    }],
    staticClass: "d-flex flex-column h-100 py-xl-20px"
  }, [_c('div', [_c('p', {
    staticClass: "txt txt--sm txt--dark mb-8px"
  }, [_vm._v(_vm._s(_vm.categoryText) + " (" + _vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : (_vm$board$createdAt = _vm$board.createdAt) === null || _vm$board$createdAt === void 0 ? void 0 : (_vm$board$createdAt$t = _vm$board$createdAt.toDate) === null || _vm$board$createdAt$t === void 0 ? void 0 : (_vm$board$createdAt$t2 = _vm$board$createdAt$t.call(_vm$board$createdAt)) === null || _vm$board$createdAt$t2 === void 0 ? void 0 : (_vm$board$createdAt$t3 = _vm$board$createdAt$t2.replaceAll) === null || _vm$board$createdAt$t3 === void 0 ? void 0 : (_vm$board$createdAt$t4 = _vm$board$createdAt$t3.call(_vm$board$createdAt$t2, "-", ".")) === null || _vm$board$createdAt$t4 === void 0 ? void 0 : (_vm$board$createdAt$t5 = _vm$board$createdAt$t4.slice) === null || _vm$board$createdAt$t5 === void 0 ? void 0 : _vm$board$createdAt$t5.call(_vm$board$createdAt$t4, 0, 7)) + ")")]), _c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v(_vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.subject))])]), _c('div', {
    staticClass: "btn-wrap mt-auto"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-200px",
    attrs: {
      "x-large": "",
      "to": `/community/${(_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.category}/${(_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : _vm$board4._id}`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("보러가기")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }