<template>
    <client-page>
        <page-section>
            <v-container>
                <div class="tit-wrap">
                    <div class="tit">개인정보처리방침</div>
                </div>
                <terms-component code="privacy" />
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TermsComponent,
    },
};
</script>
